import React from 'react'

export default function SectionTitle({title}) {
  return (
    <div className="titleSec"><h3 style={{
        "margin": "5px 0",
        "width": "max-content"
    }}>{title}</h3></div>
  )
}

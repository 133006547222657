import {NavLink} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import "./Breadcrumbs.scss";
import {useTranslation} from "react-i18next";
import Services from "../../components/Pages/Services";
import Solutions from "../../components/Pages/Solutions";
import Contact from "../../components/Pages/Contact";
import UnlimitedCalls from "../../components/Pages/UnlimitedCalls";
import VirtualNumbers from "../../components/Pages/VirtualNumbers";
import CallTransfer from "../../components/Pages/CallTransfer";
import SVI from "../../components/Pages/SVI";
import Voip from "../../components/Pages/Voip";
import C2c from "../../components/Pages/C2c";
import VideoConferencing from "../../components/Pages/VideoConferencing";
import Voicemail from "../../components/Pages/Voicemail";
import Sms from "../../components/Pages/Sms";
import CallTags from "../../components/Pages/CallTags";
import CallCenter from "../../components/Pages/CallCenter";
import ContactCenter from "../../components/Pages/ContactCenter";
import IVR from "../../components/Pages/IVR";
import Webphone from "../../components/Pages/Webphone";
import RemoteWork from "../../components/Pages/RemoteWork";
import Teamwork from "../../components/Pages/Teamwork";

export default function Breadcrumbs(){
    const {t, i18n} = useTranslation('header');
    const servicesSub={
        "ip-phone": t('header:services.ip-phone'),
        "predictive-dialer": t('header:services.predictive-dialer'),
        "robot-dialer": t('header:services.robot-dialer'),
        "unlimited-calls": t('header:services.unlimited-calls'),
        "virtual-numbers": t('header:services.virtual-numbers'),
        "call-transfer": t('header:services.call-transfer'),
        "svi": t('header:services.svi'),
        "voip": t('header:services.voip'),
        "c2c": t('header:services.c2c'),
        "video-conferencing": t('header:services.video-conferencing'),
        "voicemail": t('header:services.voicemail'),
        "sms": t('header:services.sms'),
        "call-tags": t('header:services.call-tags'),
    }
    const solutionsSub={
        "call-center-interface": t('solutions.call-center-interface'),
        "contact-center": t('solutions.contact-center'),
        "ivr": t('solutions.ivr'),
        "webphone": t('solutions.webphone'),
        "remote-work": t('solutions.remote-work'),
        "teamwork": t('solutions.teamwork'),
    }
    const routes = {
        "": {title:t('home')},
        "services": {title:t('services.title'),
            subRoutes: servicesSub},
        "pricing": {title:t('pricing')},
        "why_us": {title:t('header:why-cool-telecom')},
        "solutions": {title:t('solutions.title'),
            subRoutes: solutionsSub},
        "contact_us": {title:t('header:contact-us')},
    }
    const breadcrumbs = useBreadcrumbs();
    let languages = i18n.services.languageUtils.options.whitelist;
    let mergedPath = []
    return <div className={"breadcrumb"}>
        {breadcrumbs.map(({ match,breadcrumb },index) => {
            let title = false
            if(match.pathname === "/"){
                title = routes[""]?.title
            }else if(match.pathname === `/${i18n.language}`){
                title = false
            }
            else{
                try{
                    let currPath = match.pathname.replace(`/${i18n.language}/`,"")
                    let section = currPath.split("/")
                    if(section.length === 2){
                        title = routes[section[0]]?.subRoutes[section[1]]
                    }else if(section.length === 1){
                        title = routes[section[0]].title
                    }
                }
                catch (e) {
                    console.log(e)
                }
            }
            let size = breadcrumbs.length
            return title && (<>{size > index + 1 && size > 2?
                <NavLink key={match.pathname} to={match.pathname === "/" ? `/${i18n.language}` : match.pathname}>
                    {title ?? breadcrumb}
                </NavLink> : <span>{title}</span>}<NavigateNextIcon/></>)
        })}
    </div>
}
import webphone from "./assets/webphone.png?as=webp"
import c2c from "./assets/c2c.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
export default function Index(){
    const {t, i18n} = useTranslation('c2c');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={webphone}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <ThirdSection src={c2c} title={t('explanations.title')} subTitle={t('explanations.text')}>
            <div>
                <Paragraph title={t('explanations.time.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('explanations.time.text')}}/>
                </Paragraph>
                <Paragraph title={t('explanations.definition.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('explanations.definition.text')}}/>
                </Paragraph>
                <Paragraph title={t('explanations.howto.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('explanations.howto.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
    </>)
}
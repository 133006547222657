import svi from "./assets/svi.png?as=webp"
import digital from "./assets/digital.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import SixPack from "../../../UI/Sections/SixPack/SixPack";
import shorten from "../Home/assets/images/shortenNumber.png?as=webp";
import Checklist from "../../../UI/Checklist/Checklist";
export default function SVI(){
    const {t, i18n} = useTranslation('svi');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={svi}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('explanations.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('explanations.text')}}/>
        </RegularSection>
        <ThirdSection src={digital} title={t('upgrades.title')}>
            <div>
                <Paragraph title={t('upgrades.customize.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('upgrades.customize.text')}}/>
                </Paragraph>
                <Paragraph title={t('upgrades.image.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('upgrades.image.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>

        <SixPack title={t('soa.title')}
                 subText={t('soa.text')}
                 packs={[
                     {src:shorten,title:t('soa.redirect.title'), text:t('soa.redirect.text')},
                     {src:shorten,title:t('soa.levels.title'), text:t('soa.levels.text')},
                     {src:shorten,title:t('soa.datas.title'), text:t('soa.datas.text')},
                     {src:shorten,title:t('soa.vocals.title'), text:t('soa.vocals.text')},
                     {src:shorten,title:t('soa.extern.title'), text:t('soa.extern.text')},
                 ]}/>
        <RegularSection title={t('analyse.title')} background={"var(--blue-azure)"}>
            <div>
                <Paragraph title={t('analyse.visualize.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('analyse.visualize.text')}}/>
                </Paragraph>
                <Paragraph title={t('analyse.statistics.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('analyse.visualize.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>
        <RegularSection title={t('analyse.title')} background={"var(--blue-azure)"}>
            <div>
                <Paragraph title={t('analyse.visualize.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('analyse.visualize.text')}}/>
                </Paragraph>
                <Paragraph title={t('analyse.statistics.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('analyse.statistics.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>
        <RegularSection title={t('easy.title')}>
            <span className={"small-text"}>{t('easy.text')}</span>
            <div>
                <Paragraph title={t('easy.vocals.title')} reduced={true}>
                    <Checklist array={[t('easy.vocals.one'),t('easy.vocals.two'),t('easy.vocals.three')]}/>
                </Paragraph>
            </div>
        </RegularSection>

    </>)
}
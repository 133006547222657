import "./styleguide.css";
import './App.css';
import './UI/Button/Button.css'
import Router from "./components/Router";
import {useEffect, useState, Suspense} from "react";
import {useDispatch, useSelector} from "react-redux";
import {appActions} from "./store/app-data-slice";
import {useTranslation} from "react-i18next";
import {ReactNotifications} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import Spinner from "./UI/Spinner";
import Drift from "react-driftjs";
import ReactGA from "react-ga4";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ChatwootWidget from "./components/Chatwoot";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
const AW_TRACKING_ID = process.env.REACT_APP_AW_ID;
const GA_TRACKING_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize([{trackingId: AW_TRACKING_ID}, {trackingId:GA_TRACKING_ID}]);

function App() {
    const [mobile, setMobile] = useState(window.innerWidth <= 500);
    const selectedRoute = useSelector(state => state.app.route);
    const selectedSub = useSelector(state => state.app.subRoute);
    const isMobile = useSelector(state => state.app.isMobile);
    const userEmail = useSelector(state => state.app.userEmail);
    const { setPathPageView, setIdentity } = useTrackingCode();
    const {t, i18n} = useTranslation();
    let languages = i18n.services.languageUtils.options.whitelist;
    const dispatch            = useDispatch();
    document.documentElement.lang = i18n.language;
    const handleWindowSizeChange = () => {
        let ua = navigator.userAgent;
        let mobileStyle = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
        if(mobileStyle || window.innerWidth < 1000){
            dispatch(appActions.updateMobileStyle(true))
        }
        else{
            dispatch(appActions.updateMobileStyle(false))
        }
        setMobile(mobileStyle);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(()=>{
        let ua = navigator.userAgent;
        let mobileStyle = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
        if(mobileStyle || window.innerWidth < 1000){
            dispatch(appActions.updateMobileStyle(true))
            setMobile(true)
        }
        else{
            dispatch(appActions.updateMobileStyle(false))
            setMobile(false)
        }
    },[])

    useEffect(()=>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(userEmail)){
            setIdentity(userEmail)
        }
    },[userEmail])

    let currRoute = window.location.href.split(window.location.origin)[1].split(`/${i18n.language}`)[1]

  return (
      <Suspense fallback={<Spinner/>}>
          <Helmet>
              <link rel={"canonical"} href={`${window.location.origin}/${i18n.language}${(selectedRoute??"home") !== "home"?currRoute:""}`}/>
              {languages.filter(ln => ln !== i18n.language).map(ln =><link rel={"alternate"} href={`${window.location.origin}/${ln}${(selectedRoute??"home") !== "home"?currRoute:""}`}/>)}

          </Helmet>
              <ReactNotifications/>
          <div className={`App ${!isMobile  ? "regular" : "mobile"} ${selectedSub === "not-found"? "not-found":""}`.trim()}>
              <Router/>
              {/* <Drift
                  appId="rn2wu6iwzfse"
                  attributes={{ domain: "cool-telecom.com" }}
              /> */}
            <ChatwootWidget/>
          </div>
      </Suspense>
  );
}

export default App;

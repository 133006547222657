import ivr from "./assets/ivr.png?as=webp"
import interfaces from "./assets/interfaces.png?as=webp"
import tools from "./assets/tools.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import SixPack from "../../../UI/Sections/SixPack/SixPack";
import welcome from "./assets/welcome.png?as=webp";
import music from "./assets/music.png?as=webp";
import calls from "./assets/calls.png?as=webp";
import number from "./assets/number.png?as=webp";
import record from "./assets/record.png?as=webp";
import unlimited from "./assets/unlimited.png?as=webp";
import Checklist from "../../../UI/Checklist/Checklist";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function IVR(){
    const {t, i18n} = useTranslation(['ivr','common']);
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={ivr}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('easy.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('easy.text')}}/>
            <LazyLoadImage  className="exmple-coolTelecom" src={interfaces}/>
        </RegularSection>
        <ThirdSection src={tools} title={t('advantages.title')}>
            <div>
                <Paragraph title={t('advantages.self.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.self.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.welcome.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.welcome.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.manage.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.manage.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('collaborate.title')} background={"var(--blue-azure)"} showDots={false}>
            <div>
                <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('collaborate.text')}}/>
            </div>
        </RegularSection>

        <RegularSection title={t('size.title')} background={"var(--gray-cultured)"} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('size.text')}}/>
            <div className={"sub-tabs-wrapper"}>
                <div className={"sub-tabs"}>
                    <span style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html:  t('size.small.title')}}/>
                    <Checklist array={[t('size.small.list.self'),t('size.small.list.activate')]}/>
                    <button className={"round-light"}>{t('common:try-free')}</button>
                </div>
                <div className={"sub-tabs"}>
                    <span style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html:  t('size.big.title')}}/>
                    <Checklist array={[t('size.big.list.custom')]}/>
                    <button className={"round-light"}>{t('common:ask-demo')}</button>
                </div>
            </div>
        </RegularSection>

        <SixPack title={t('functionalities.title')}
                 packs={[
                     {src:welcome,title:t('functionalities.welcome.title'), text:t('functionalities.welcome.text')},
                     {src:music,title:t('functionalities.await.title'), text:t('functionalities.await.text')},
                     {src:number,title:t('functionalities.ivr.title'), text:t('functionalities.ivr.text')},
                     {src:calls,title:t('functionalities.calls.title'), text:t('functionalities.calls.text')},
                     {src:record,title:t('functionalities.record.title'), text:t('functionalities.record.text')},
                     {src:unlimited,title:t('functionalities.unlimited.title'), text:t('functionalities.unlimited.text')}
                 ]}/>
    </>)
}
import {Dropdown} from "semantic-ui-react";
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Tooltip} from "@mui/material";
import fr_flag from "./assets/images/fr-flag.png";
import en_flag from "./assets/images/en-flag.png";
import il_flag from "./assets/images/il-flag.png"

import "semantic-ui-css/components/dropdown.css";
import "semantic-ui-css/components/image.css";
import "semantic-ui-css/components/transition.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./LanguageSelection.scss";
import {appActions} from "../../store/app-data-slice";


const Index =({ t, showText=true, disabled = false, setCollapsed=undefined}) => {
    const userLocation = useSelector(state => state.app.location);
    const language = useSelector(state => state.app.language);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const parseRoute = (route) =>{
        const regex = /\/(.*?)\//g;
        const matches = route.match(regex);
        if(matches !== null){
            const outputArray = matches.map(match => match.substring(1, match.length - 1));
            return outputArray.join(", ");
        }
        else{
            return ""
        }
    }
    const countryTolang = {
        "IL":"heb",
        "FR":"fr"
    }
    useEffect(()=>{
        let languages = i18n.services.languageUtils.options.whitelist;
        let pathLang = languages.filter(lang => location.pathname.includes(lang))[0]??false
        let parsed = (location.pathname.length > 3? parseRoute(location.pathname):pathLang?pathLang:"none").toUpperCase()
        let lang = ""
        let ipLang = countryTolang[userLocation.country_code] ?? "fr"
        if(parsed === "NONE" || !languages.includes(parsed.toLowerCase())){
            i18n.changeLanguage("fr");
            lang = "fr"
        }
        else if(parsed.toLowerCase() !== i18n.language){
            i18n.changeLanguage(parsed.toLowerCase());
            lang = parsed.toLowerCase()
        }
        dispatch(appActions.updateLanguage(lang))
        if(location?.pathname.replaceAll("/","") === "dialer-robot"){
            //navigate(`/${lang}/services/robot-dialer`)
        }
        else if(location?.pathname.replaceAll("/","") === "ip-phone"){
            //navigate(`/${lang}/services/ip-phone`)
        }
        else if(location?.pathname.replaceAll("/","") === "predictive-dialer"){
            //navigate(`/${lang}/services/predictive-dialer`)
        }
        else if(location?.pathname.replaceAll("/","") === "contact-us"){
            //navigate(`/${lang}/contact_us`)
        }
    },[userLocation])
    const { i18n } = useTranslation();
    const changeLanguage = (event,data) => {
        i18n.changeLanguage(data.value);
        dispatch(appActions.updateLanguage(data.value))
    }
    const langOptions = [
        {
            key: 'French',
            text: 'Francais',
            value: 'fr',
            image: { avatar: true, src: fr_flag },
        },
        {
            key: 'English',
            text: 'English',
            value: 'en',
            image: {avatar: true, src: en_flag }
        },
        {
            key: 'Hebrew',
            text: 'עברית',
            value: 'heb',
            image: {avatar: true, src: il_flag}
        }
    ]

    const removeText = (list) =>{
        Object.values(list).forEach((el)=> {
            delete el.text
        })
        return(list);
    }

    return(
        <Tooltip title={disabled?"Language selection isn't available currently. We're working on it.":""}>
            <div className={"language-select" + (showText? " show-text":"")}>
                <Dropdown
                    placeholder='Select language'
                    fluid
                    selection
                    disabled={disabled}
                    onChange={changeLanguage}
                    options={showText?langOptions:removeText(langOptions)}
                    value={i18n.language===undefined?'fr':i18n.language}
                >
                    <Dropdown.Menu>
                        {(showText?langOptions:removeText(langOptions)).map((opt, index) =>{
                            let languages = i18n.services.languageUtils.options.whitelist;
                            let pathLang = languages.filter(lang => location.pathname.includes(lang))[0]
                            let currRoute = location.pathname.split(`/${pathLang}/`)[1]
                            return <Dropdown.Item text={opt?.text} key={`${opt?.text}-${index}`} image={opt?.image} as={Link} to={`/${opt?.value}${location.pathname === "/"?"": (currRoute!=undefined&&currRoute!=null&&currRoute.length > 0)?`/${currRoute}`:""}`}/>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Tooltip>
    )
}

export default Index;
import logo from "../Header/assets/Logo_cool_telecom.svg";
import { IL, FR, GB, BE } from 'country-flag-icons/react/1x1'
import { SocialIcon } from 'react-social-icons';
import "./Footer.css";
import {useSelector} from "react-redux";
import Collapsable from "../../UI/Collapsable/Collapsable";
import {useTranslation} from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";

export default function Footer(){
    const ColoredLine = ({ color="black" }) => (
        <div style={{ borderTop: `2px solid ${color}`, marginLeft: 20, marginRight: 20, width:"100%"}}/>
    );
    const {t, i18n} = useTranslation('footer');
    const isMobile = useSelector(state => state.app.isMobile);
    return(
        <div className="footer">
            <div className={"footer-separator"}><LazyLoadImage  style={{maxHeight: "40px"}} src={logo}/><ColoredLine color={"var(--green-crayola)"}/></div>
            <div className="infos">
                <>
                    {isMobile?
                        <Collapsable title={t('contact-us')}>
                            <div className={"footer-columns hours"}>
                                <span style={{cursor:"pointer"}}><a href="mailto:support@cool-telecom.com">support@cool-telecom.com</a></span>
                                <span className={"text-details"} style={{padding:"0"}}>CoolTelecom France</span>
                                <span className={"text-details"} style={{padding:"0"}}>8 rue Gaston margerie, 94130 Nogent sur marne</span>
                                <span className={"number"}><FR className={"flags"}/><a href={"tel:+33159180026"}>(+33)159180026</a></span>
                                <span className={"number"}><IL className={"flags"}/><a href={"tel:+972779909233"}>(+972)779909233</a></span>
                                <span className={"number"}><GB className={"flags"}/><a href={"tel:+442035190975"}>(+44)2035190975</a></span>
                                <span className={"number"}><BE className={"flags"}/><a href={"tel:+3228992884"}>(+32)28992884</a></span>
                            </div>
                        </Collapsable>:<div className={"footer-columns contact-us"}>
                            <span className={"footer-title"}>{t('contact-us')}</span>
                            <span style={{cursor:"pointer"}}><a href="mailto:support@cool-telecom.com">support@cool-telecom.com</a></span>
                            <span className={"text-details"} style={{padding:"0"}}>CoolTelecom France</span>
                            <span className={"text-details"} style={{padding:"0"}}>8 rue Gaston margerie, 94130 Nogent sur marne</span>
                            <span className={"number"}><FR className={"flags"}/><a href={"tel:+33159180026"}>(+33)159180026</a></span>
                            <span className={"number"}><IL className={"flags"}/><a href={"tel:+972779909233"}>(+972)779909233</a></span>
                            <span className={"number"}><GB className={"flags"}/><a href={"tel:+442035190975"}>(+44)2035190975</a></span>
                            <span className={"number"}><BE className={"flags"}/><a href={"tel:+3228992884"}>(+32)28992884</a></span>
                        </div>}
                </>
                <>
                    {isMobile?
                        <Collapsable title={t('hours.title')}>
                            <div className={"footer-columns hours"}>
                                <span>{t('hours.week')}</span>
                                <span>{t('hours.technical')}</span>
                            </div>
                        </Collapsable>:
                        <div className={"footer-columns hours"}>
                            <span className={"footer-title"}>{t('hours.title')}</span>
                            <span>{t('hours.week')}</span>
                            <span>{t('hours.technical')}</span>
                        </div>}
                </>
                <div className={"footer-columns socials"}>
                    <span className={"footer-title"}>{t('follow-us')}</span>
                    <span style={{
                        columnGap: "2px",
                        display: "flex",
                    }}>
                        <SocialIcon network="facebook" href={"https://www.facebook.com/CoolTelecomFR"}/>
                        <SocialIcon network="instagram" href={"https://www.instagram.com/cooltelecom_/?hl=fr"}/>
                        <SocialIcon network="linkedin" href={"https://www.linkedin.com/company/cool-telecom/mycompany/"}/>
                        <SocialIcon network="youtube" href={"https://www.youtube.com/@cooltelecom1794"}/>
                        <SocialIcon network="twitter" href={"https://twitter.com/cool_telecom"}/>
                    </span>
                </div>
            </div>
        </div>
    )
}
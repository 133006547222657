import connected from "./assets/connected.png?as=webp"
import interfaces from "./assets/interface.png?as=webp"
import woman from "./assets/woman-talk.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SixPack from "../../../UI/Sections/SixPack/SixPack";
import shorten from "../Home/assets/images/shortenNumber.png?as=webp";
import transfer from "../Home/assets/images/callTransfer.png?as=webp";
import supervision from "../Home/assets/images/supervision.png?as=webp";
import number from "../Home/assets/images/number.png?as=webp";
import click from "../Home/assets/images/click.png?as=webp";
import record from "../Home/assets/images/record.png?as=webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.css"
export default function CallCenter(){
    const {t} = useTranslation('call-center-interface');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={connected}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('easy.title')} showDots={false}>
            <span className={'small-text'} style={{
                paddingBottom: "20px"
            }} dangerouslySetInnerHTML={{__html: t('easy.text')}}/>
            <LazyLoadImage  className="exmple-coolTelecom" src={interfaces}/>
        </RegularSection>
        <ThirdSection src={woman} title={t('advantages.title')}>
            <div>
            <Paragraph title={t('advantages.coach.title')} reduced={true}>
                <span dangerouslySetInnerHTML={{__html: t('advantages.coach.text')}}/>
            </Paragraph>
            <Paragraph title={t('advantages.kpi.title')} reduced={true}>
                <span dangerouslySetInnerHTML={{__html: t('advantages.kpi.text')}}/>
            </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('adapt.title')} background={"var(--blue-azure)"}>
            <div>
                <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('adapt.text')}}/>
            </div>
        </RegularSection>
        <SixPack title={t('the-best.title')}
                 packs={[
                     {src:shorten,title:t('the-best.shorten.title'), text:t('the-best.shorten.text')},
                     {src:transfer,title:t('the-best.transfer.title'), text:t('the-best.transfer.text')},
                     {src:supervision,title:t('the-best.supervision.title'), text:t('the-best.supervision.text')},
                     {src:number,title:t('the-best.number.title'), text:t('the-best.number.text')},
                     {src:click,title:t('the-best.click.title'), text:t('the-best.click.text')},
                     {src:record,title:t('the-best.record.title'), text:t('the-best.record.text')}
                 ]}/>
    </>)
}
import callcenter from "./assets/callcenter.png?as=webp"
import {getAuthClient} from "../../../utils/auth";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import {BE, FR, GB, IL} from "country-flag-icons/react/1x1";
import React, {Suspense, useCallback, useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Helmet} from "react-helmet-async";
const AW_TRACKING_ID = process.env.REACT_APP_AW_ID;
const GA_TRACKING_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize([{trackingId: AW_TRACKING_ID}, {trackingId:GA_TRACKING_ID}]);
export default function Contact(){
    const {t, i18n} = useTranslation('contact-us');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const userLocation = useSelector(state => state.app.location);
    const defaultValues = {
        name:"",
        company:"",
        phone:"",
        address:"",
        country:"",
        email:""
    }
    const auth = getAuthClient();
    const [values, setValues] = useState(defaultValues)
    const isMobile = useSelector(state => state.app.isMobile);
    const handleChange = (event) =>{
        setValues({...values,[event.target.name]: event.target.value});
    }
    const [token, setToken] = useState('')
    const verifyRecaptchaCallback = useCallback((token) => {
        setToken(token)
    },[values]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        let body = {
            subject: "Demande d'information Cool-Telecom.",
            from:values.email,
            html:
                '<!DOCTYPE html>' +
                '<div>' +
                '<p>Ces informations ont été envoyé à partir de la page "Contactez-nous" du site Cool-Telecom</p>' +
                `<p>Nom: ${values.name}</p>` +
                `<p>Téléphone: ${values.phone}!</p>` +
                (values?.company.length> 0?`<p>Société: ${values.company}</p>`:"") +
                (values?.address.length> 0?`<p>Adresse: ${values.address}</p>`:"") +
                (values?.country.length> 0?`<p>Pays: ${values.country}</p>`:"") +
                `<p>Mail: ${values.email}</p>` +
                `<p>IP: ${userLocation?.ip} || ${userLocation?.version}</p>`+
                '</div>' +
                '</body></html>',
        }
        await auth.setCRMData("/messages/sendEmailSite", {message:body, token},{}).then(res => {
            console.log(res)
            ReactGA.event({
                category: "form",
                action: "form_submit",
                label: "Contact Us"
            });
            setRefreshReCaptcha(r => !r);
        }).catch(err => {
            console.log(err)
        })
    }
    return(
        <GoogleReCaptchaProvider reCaptchaKey={"6Le8wWofAAAAADmvB7GVe-pCZWqMQ5oaCYvAHzy0"}>
            <Helmet>
                <title>{`${t('title')} - Cool Telecom`}</title>
            </Helmet>
        <RegularSection extraClasses={'contact-us-header'} showDots={false} background={"var(--gray-cultured)"}>
            <LazyLoadImage  className="exmple-coolTelecom" src={callcenter}/>
        </RegularSection>
        <RegularSection title={t('title')} extraClasses={"body-contact"} showDots={true}>
            <div className={"contact-us-wrapper"}>
            <div className={"footer-columns contact-us"}>
                <span style={{cursor:"pointer"}}><a href="mailto:support@cool-telecom.com">support@cool-telecom.com</a></span>
                <span className={"text-details"} style={{padding:"0"}}>CoolTelecom France</span>
                <span className={"text-details"} style={{padding:"0"}}>8 rue Gaston margerie, 94130 Nogent sur marne</span>
                <span className={"number"}><FR className={"flags"}/><a href={"tel:+33159180026"}>(+33)159180026</a></span>
                <span className={"number"}><IL className={"flags"}/><a href={"tel:+972779909233"}>(+972)779909233</a></span>
                <span className={"number"}><GB className={"flags"}/><a href={"tel:+442035190975"}>(+44)2035190975</a></span>
                <span className={"number"}><BE className={"flags"}/><a href={"tel:+3228992884"}>(+32)28992884</a></span>
            </div>
            <form onSubmit={handleSubmit}>
                <label className={"required"}>{t('form.name')}<span/></label><input name={"name"} value={values?.name} onChange={handleChange} required={true}/>
                <label className={"required"}>{t('form.phone')}<span/></label><input name={"phone"} value={values?.phone} onChange={handleChange} required={true}/>
                <label>{t('form.company')}<span/></label><input name={"company"} value={values?.company} onChange={handleChange}/>
                <label>{t('form.address')}<span/></label><input name={"address"} value={values?.address} onChange={handleChange}/>
                <label>{t('form.country')}<span/></label><input name={"country"} value={values?.country} onChange={handleChange}/>
                <label  className={"required"}>{t('form.email')}<span/></label><input name={"email"} value={values?.email} onChange={handleChange} required={true}/>
                <div className={"button-row"}/>
                <GoogleReCaptcha
                    onVerify={verifyRecaptchaCallback}
                    refreshReCaptcha={refreshReCaptcha}
                />
                <button type={"submit"}>{t('form.send')}</button>
            </form>
            </div>
        </RegularSection>
        </GoogleReCaptchaProvider>)
}
/**
 * @file
 *
 * Wrapper around fetch(), and OAuth access token handling operations.
 *
 * To use import getAuthClient, and initialize a client:
 * const auth = getAuthClient(optionalConfig);
 */
import axios from "axios";
import store from "../store";
//import { iso1A2Code } from '@rapideditor/country-coder';
import {Store} from "react-notifications-component";
//import {helpFunc} from "./helpFunc";

/**
 * OAuth client factory.
 *
 * @param {object} config
 *
 * @returns {object}
 *   Returns an object of functions with $config injected.
 */
export function getAuthClient(config = {}) {
    const conf = {
        // Base URL of your Drupal site.
        base: process.env.REACT_APP_SERVER_URL,
        // url for crm
        base_crm: process.env.REACT_APP_CRM_BASE
    };
    const notifClass = {
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        slidingExit: {
            duration: 800,
            timingFunction: 'ease-out',
            delay: 0
        },
        dismiss: {
            duration: 10000,
            onScreen: true,
            showIcon:true,
            pauseOnHover: true
        }
    }
    async function token() {
        const token = sessionStorage.getItem(conf.token_name) !== null
            ? JSON.parse(sessionStorage.getItem(conf.token_name))
            : false;

        if (!token) {
            return Promise.reject(false);
        }

        const {expires_at, refresh_token} = token;
        if (expires_at - conf.expire_margin < Date.now() / 1000) {
            //return refreshToken(refresh_token);
            return ""
        }
        return Promise.resolve(token);
    };
    ///////////////////////////////////////////CRM////////////////////////////////////////////////
    /**
     * Update CRM Data
     */
    async function updateCRMData(url, post_data= {}, contentType = "application/json"){
        const   oauth_token = await token();
        var     bearer = '';
        var     form_data = new FormData();
        if (oauth_token) {
            post_data.bearer =  `Bearer ${oauth_token.access_token}`;
            bearer =  `Bearer ${oauth_token.access_token}`;
        }
        for ( var key in post_data ) {
            form_data.append(key, post_data[key]);
        }
        try {
            let res = await axios({
                method: "put",
                url: `${conf.base_crm}${url}`,
                data: post_data,
                headers: {
                    'Accept': '*/*',
                    'Content-Type': contentType,
                    'Authorization': bearer
                }
            })
            if(res.status === 200){
                successHandler(res)
                console.log(res.status)
            }
            return res.data
        }
        catch (err) {
            errorHandler(err,{type:"UPDATE"})
            return err
        }
    }

    /**
     * Set CRM Data (new object)
     */
    async function setCRMData(url, post_data= {}, {content = 'application/json', showError= true, showSuccess= true, messageSuccess = false, messageError = false}){
        //const   oauth_token = await token();
        var     form_data = new FormData();
        for ( var key in post_data ) {
            form_data.append(key, post_data[key]);
        }

        try {
            let res = await axios({
                method: "post",
                url: `${conf.base_crm}${url}`,
                data: post_data,
                headers: {
                    'Accept': '*/*',
                    'Content-Type': content,
                    //'Authorization': bearer
                }
            })
            if(res.status === 200 && showSuccess){
                successHandler(res, messageSuccess)
                console.log(res.status)
            }
            return res.data
        }
        catch (err) {
            if(showError){
                errorHandler(err,{type:"CREATE"})
            }
            console.log('API got an error', err);
        }
    }

    /**
     * Delete CRM Data (new object)
     */
    async function deleteCRMData(url, id){
        const   oauth_token = await token();
        var     bearer = '';
        if (oauth_token) {
            bearer =  `Bearer ${oauth_token.access_token}`;
        }

        try {
            let res = await axios({
                method: "delete",
                url: `${conf.base_crm}${url}/${id}`,
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            })
            if(res.status === 200){
                Store.addNotification({
                    title: "Success!",
                    message: `Delete operation was successful. ${res.data}`,
                    type: "success",
                    ...notifClass
                });
                console.log(res.status)
            }
            return res.data
        }
        catch (err) {
            errorHandler(err, {type:"DELETE"})
        }
    }

    /**
     * Get CRM Data
     */
    async function getCRMData(url, post_data= {}, extraOptions = {}){
        let configAxios = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        };

        try {
            configAxios = {...configAxios, ...extraOptions}
            const response = await axios.get(`${conf.base_crm}${url}`,post_data, configAxios);
            const responce_data = await response.data;
            return responce_data;
        } catch (err) {
            errorHandler(err)
        }
    }

    function errorHandler(err, params={}){
        let {message = false, type = false} = params;
        let state = store.getState()
        let logged = state?.auth?.isLoggedIn
        if(err?.response?.status === 401 && logged){
            //store.dispatch(authActions.logout())
            Store.addNotification({
                title: "Attention!",
                message: "Invalid token. Your connexion was reset.",
                type: "warning",
                ...notifClass
            });
        }
        else if(message){
            Store.addNotification({
                title: "Attention!",
                message: message,
                type: "warning",
                ...notifClass
            });
            return err;
        }
        else if(type){
            let url = err?.config?.url
            let funcName = url.slice(-1) === "/"? url.slice(0, -1).split("/").pop():url.split("/").pop()
            if(type === "DELETE" || type === "UPDATE"){
                funcName = err?.config?.url.split("/")
                funcName = funcName[funcName.length - 2]
            }
            //funcName = helpFunc().capitalize(funcName.replace(/([A-Z])/g, ' $1').trim())
            let dataType = funcName.split(" ").pop()
            let message = err?.response?.data?.message??err?.message

            Store.addNotification({
                title: `Couldn't ${type.toLowerCase()} the ${dataType}.`,
                message: <span dangerouslySetInnerHTML={{ __html: message }}/>,
                type: "warning",
                ...notifClass
            });
            return err;
        }
        else{
            return err;
        }
    }


    function successHandler(res, customMessage=false) {
        if (res?.status === 200) {
            let url = res?.config?.url
            let funcName = url.slice(-1) === "/"? url.slice(0, -1).split("/").pop():url.split("/").pop()
            //funcName = helpFunc().capitalize(funcName.replace(/([A-Z])/g, ' $1').trim())
            let dataType = funcName.split(" ").pop()
            Store.addNotification({
                title: "Success!",
                message: customMessage? customMessage:(res?.data?.message??`Message envoyé!`),
                type: "success",
                ...notifClass
            });
        }
        else{
            Store.addNotification({
                title: "Error!",
                message: `API got an error ${res?.message}`,
                type: "danger",
                ...notifClass
            })
        }
        console.log(res)
    }

    async function getGeoInfo(){
        try {
            const response = await axios.get('https://ipapi.co/json/').catch(async e =>{
                return await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
            })
            if("coords" in response){
                let country_code = await setCRMData(`/siteCustom/countryCode`, [parseFloat(response.coords.longitude.toFixed(2)), parseFloat(response.coords.latitude.toFixed(2))],{showSuccess: false, showError:false}).then(res =>{
                    return res
                })
                //let country_code = iso1A2Code([parseFloat(response.coords.longitude.toFixed(2)), parseFloat(response.coords.latitude.toFixed(2))])
                if(country_code === null){
                    throw "Not valid coordinates"
                }
                else{
                    return {...response.coords, country_code}
                }
            }
            else{
                const responce_data = await response.data;
                return responce_data;
            }
        } catch (err) {
            throw err
        }
    };

    return {
        setCRMData,
        updateCRMData,
        getCRMData,
        deleteCRMData,
        getGeoInfo,
    };
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from "i18next-xhr-backend";
// the translations

const DETECTION_OPTIONS = {
    order: ['sessionStorage', 'navigator'],
    caches: ['sessionStorage']
};

i18n
    // pass the i18n instance to react-i18next.
    .use(backend)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        detection: DETECTION_OPTIONS,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        whitelist: ['en', 'fr', 'heb'],
        fallbackLng: ['fr'],
    });
export default i18n;
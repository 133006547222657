import {useSelector} from "react-redux";
import Carousel from "react-material-ui-carousel";
import {Paper} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./SixPack.css";
import React, {useRef} from "react";
import {useIsVisible} from "../../../utils/help";
import {useTranslation} from "react-i18next";

export default function SixPack({title="", subText="", packs=[], id="", wrapperStyle={background:"var(--gray-cultured)",}, titleWrapper={padding: "2% 0 5%"}, children=false}){
    const { i18n } = useTranslation();
    const ColoredLine = ({ color="black" }) => (
        <div className={"colored-line"} style={{ borderTop: `2px solid ${color}`, marginLeft: 10, marginRight: 10, minWidth:"10%"}}/>
    );

    const ref = useRef();
    const isVisible = useIsVisible(ref)
    const isMobile = useSelector(state => state.app.isMobile);
    return<div className={`six-pack ${isVisible&&"slide"}`.trim()} id={id} style={{
        ...wrapperStyle,
        textAlign: "left",
        padding: isMobile?"1% 5%":"1% 13%",
    }}>
        <div className={"sixpack-title-wrapper"} ref={ref} style={{
            ...titleWrapper
        }}>
        <h3 style={{margin:"0",
            display: "flex",
            flexDirection: "row",
            alignItems: "center", justifyContent:'center',
            whiteSpace: isMobile?"pre":"",
            textAlign: isMobile?"center":""}}><ColoredLine color={"var(--green-crayola)"}/>{title.replace(".",".\n")} {(title.length > 0)&&<ColoredLine color={"var(--green-crayola)"}/>}</h3>
        <span style={{color:"var(--gray)", display:'flex', justifyContent:'center'}}>{subText}</span>
        </div>
        <div className={"grid-wrapper"} style={!isMobile?{
            display: "grid",
            gridTemplateColumns: "30% 30% 30%",
            columnGap: "5%",
            rowGap: "8%"
        }:{}}>
            {!isMobile?packs.map(cell=>{
                return cell?.rootUrl? <a href={cell?.rootUrl && `${i18n.language}/${cell?.rootUrl}`}><div className={"tabs"}>
                    <span className={"tab-title"}><LazyLoadImage  src={cell?.src} style={{height: "100px"}}/> {cell?.title}</span>
                    <span className={"tab-text"} style={!isMobile?{color:"var(--gray)"}:{color:"var(--gray)",marginBottom: "5%"}}  dangerouslySetInnerHTML={{__html: cell?.text}}/>
                </div></a>:<div className={"tabs"}>
                    <span className={"tab-title"}><LazyLoadImage  src={cell?.src} style={{height: "100px"}}/> {cell?.title}</span>
                    <span className={"tab-text"} style={!isMobile?{color:"var(--gray)"}:{color:"var(--gray)",marginBottom: "5%"}}  dangerouslySetInnerHTML={{__html: cell?.text}}/>
                </div>
            }):
                <Carousel autoPlay={true} indicators={true} navButtonsAlwaysInvisible={true} sx={{
                    boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}>
                    {
                        packs.map(cell => {
                            return <Paper>
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    position: "sticky",
                                    rowGap: "10px",
                                    paddingTop: "20px",
                                    paddingBottom: "20px"
                                }}>
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    columnGap: "15px"
                                }}><LazyLoadImage  src={cell?.src} style={{height: "100px"}}/> {cell?.title}</span>
                                    <span style={!isMobile ? {
                                        color: "var(--gray)",
                                        marginBottom: "15%"
                                    } : {color: "var(--gray)", marginBottom: "5%"}}
                                          dangerouslySetInnerHTML={{__html: cell?.text}}/>
                                </div>
                            </Paper>
                        })
                    }
                </Carousel>
            }
        </div>
        {children && <>{children}</>}
    </div>

}
import predictive from "./assets/predictive.png?as=webp"
import typing from "./assets/typing.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Checklist from "../../../UI/Checklist/Checklist";
import Advantages from "../../../UI/Sections/Advantages/Advantages";
import "./style.css";
export default function PredictiveDialer(){
    const {t, i18n} = useTranslation('predictive-dialer');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={typing}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('prospection.title')} showDots={false} linePos={"under"}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('prospection.text')}}/>
        </RegularSection>
        <ThirdSection src={predictive} title={t('productivity.title')}>
            <div>
                <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('productivity.text')}}/>
            </div>
        </ThirdSection>
    </>)
}
import "./RegularSection.css"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import SectionTitle from "../sectionTitle/sectionTitle";
import {useRef} from "react";
import {useIsVisible} from "../../../utils/help";

export default function RegularSection({background="white", title=false, linePos="right", extraClasses="", showDots=true, children}){
    const ref = useRef();
    const isVisible = useIsVisible(ref)
    const ColoredLine = ({ color="black" }) => (
        <div className={"colored-line"} style={{ borderTop: `2px solid ${color}`, marginLeft: 20, marginRight: 20, minWidth:"15%"}}/>
    );
    const {t, i18n} = useTranslation();
    const isMobile = useSelector(state => state.app.isMobile);
    const Title = ({title, position}) => (
        title?<div className={"titleSec"}><ColoredLine color={"var(--green-crayola)"}/><SectionTitle title={title}/><ColoredLine color={"var(--green-crayola)"}/></div>:<></>
    );
    return <div className={`section ${linePos} ${extraClasses.length > 0&&extraClasses} ${isVisible&&"slide"}`.trim()} ref={ref} style={{
        background:background,
        textAlign:isMobile && i18n.language === "heb"? "right":""
    }}>
        <Title title={title} position={linePos}/>
        {children}
        {showDots?<div className={"gray-dots"}/>:<></>}
    </div>

}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import { Helmet, HelmetProvider } from "react-helmet-async";
import App from './App';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider context={{}}>
        <Helmet>
            <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1" />
        </Helmet>
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    </HelmetProvider>
);

reportWebVitals();
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getAuthClient} from "../../../utils/auth";
import "./style.css";
import React, {useCallback, useState} from "react";
import men from "./assets/men.jpg";
import ReactGA from "react-ga4";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {GoogleReCaptchaProvider, GoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useDispatch, useSelector} from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCookie } from "../../../utils/help";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { appActions } from "../../../store/app-data-slice";


export default function Requests(callback, deps) {
    const dispatch = useDispatch();
    const { setIdentity } = useTrackingCode();
    const {type = "quotation"} = useParams();
    const {t, i18n} = useTranslation('requests');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const userLocation = useSelector(state => state.app.location);
    const defaultValues = {
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        address1: "",
        country: "",
        city: "",
        state: "",
        zip_code: "",
        password: "",
        email: ""
    }
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const auth = getAuthClient();
    const [values, setValues] = useState(defaultValues)
    const handleChange = (event) => {
        setValues({...values, [event.target.id]: event.target.value});
    }
    const [token, setToken] = useState('')
    const verifyRecaptchaCallback = useCallback((token) => {
        setToken(token)
    },[values]);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Récupération du cookie "hubspotutk"
        const hutkCookie = getCookie('hubspotutk');
    
        let body = {
            subject: `Demande d'essai Cool-Telecom.`,
            html:
                '<!DOCTYPE html>' +
                '<div>' +
                '<p>Ces informations ont été envoyées à partir de la page "Requête" du site Cool-Telecom</p>' +
                `<p>Nom: ${values.first_name} ${values.last_name}</p>` +
                `<p>Email: ${values.email}</p>` +
                `<p>Téléphone: ${values.phone}</p>` +
                (values?.company.length > 0 ? `<p>Société: ${values.company}</p>` : "") +
                (values?.address1.length > 0 ? `<p>Adresse: ${values.address1}</p>` : "") +
                (values?.country.length > 0 ? `<p>Pays: ${values.country}</p>` : "") +
                `<p>IP: ${userLocation?.ip} || ${userLocation?.version}</p>` +
                '</div>' +
                '</body></html>',
        };
    
        console.log(token);
    
        await auth.setCRMData(
            "/domains/trial-request", 
            { 
                data: {...values,hutk: hutkCookie }, 
                mail: body, 
                token,
            }, 
            { messageSuccess: "Your request was recorded. You'll soon receive an email with login instructions." }
        )
        .then(res => {
            console.log(res);
            dispatch(appActions.updateUserEmail(values?.email))
            ReactGA.event({
                category: "form",
                action: "form_submit",
                label: "Trial",
            });
            setRefreshReCaptcha(r => !r);
        })
        .catch(err => {
            console.log(err);
        });
    };


    return <GoogleReCaptchaProvider reCaptchaKey={"6Le8wWofAAAAADmvB7GVe-pCZWqMQ5oaCYvAHzy0"}>
        <div className={"section"}>
                <form onSubmit={handleSubmit}>
                    <h3>{t(`${type}`)}</h3>
                    <div className={"row-2"}>
                        <div><label className={"required"}>{t('form.first_name')}<span/></label><input id={"first_name"}
                                                                                                       value={values?.first_name}
                                                                                                       name={"firstname"}
                                                                                                       onChange={handleChange}
                                                                                                       required={true}/>
                        </div>
                        <div><label className={"required"}>{t('form.last_name')}<span/></label><input id={"last_name"}
                                                                                                      value={values?.last_name}
                                                                                                      name={"lastname"}
                                                                                                      onChange={handleChange}
                                                                                                      required={true}/>
                        </div>
                        <div><label className={"required"}>{t('form.email')}<span/></label><input id={"email"}
                                                                                                  value={values?.email}
                                                                                                  name={"email"}
                                                                                                  onChange={handleChange}
                                                                                                  required={true}
                                                                                                  type={"email"}/></div>
                        <div style={{
                            position:"relative"
                        }}><label className={"required"}>{t('form.password')} <span/></label>


                            <input id={"password"} value={values?.password} onChange={handleChange} name={"crm_password"} required={true}
                                   type={showPassword ? 'text' : 'password'}/>
                            <div className="positionIcon"><IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}

                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                            </div>

                        </div>


                        <div><label className={"required"}>{t('form.phone')}<span/></label><input id={"phone"}
                                                                                                  type={"tel"}
                                                                                                  value={values?.phone}
                                                                                                  name={"phone"}
                                                                                                  onChange={handleChange}
                                                                                                  required={true}/>
                        </div>

                        <div><label className={"required"}>{t('form.company')}<span/></label><input id={"company"}
                                                                                                    value={values?.company}
                                                                                                    name={"company"}
                                                                                                    onChange={handleChange}/>
                        </div>

                        <div><label className={"required"}>{t('form.country')}<span/></label>
                            <select name="country" id="country" className="form-control custom-select select-inline"
                                    tabIndex="13" value={values?.country}
                                    onChange={handleChange}>
                                <option value="AF">Afghanistan</option>
                                <option value="AX">Aland Islands</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AS">American Samoa</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua And Barbuda</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarus</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermuda</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BA">Bosnia And Herzegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BR">Brazil</option>
                                <option value="IO">British Indian Ocean Territory</option>
                                <option value="BN">Brunei Darussalam</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodia</option>
                                <option value="CM">Cameroon</option>
                                <option value="CA">Canada</option>
                                <option value="IC">Canary Islands</option>
                                <option value="CV">Cape Verde</option>
                                <option value="KY">Cayman Islands</option>
                                <option value="CF">Central African Republic</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CX">Christmas Island</option>
                                <option value="CC">Cocos (Keeling) Islands</option>
                                <option value="CO">Colombia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="CD">Congo, Democratic Republic</option>
                                <option value="CK">Cook Islands</option>
                                <option value="CR">Costa Rica</option>
                                <option value="CI">Cote D'Ivoire</option>
                                <option value="HR">Croatia</option>
                                <option value="CU">Cuba</option>
                                <option value="CW">Curacao</option>
                                <option value="CY">Cyprus</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="DK">Denmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominican Republic</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypt</option>
                                <option value="SV">El Salvador</option>
                                <option value="GQ">Equatorial Guinea</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Ethiopia</option>
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                <option value="FO">Faroe Islands</option>
                                <option value="FJ">Fiji</option>
                                <option value="FI">Finland</option>
                                <option value="FR">France</option>
                                <option value="GF">French Guiana</option>
                                <option value="PF">French Polynesia</option>
                                <option value="TF">French Southern Territories</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="DE">Germany</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Greece</option>
                                <option value="GL">Greenland</option>
                                <option value="GD">Grenada</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HM">Heard Island &amp; Mcdonald Islands</option>
                                <option value="VA">Holy See (Vatican City State)</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="HU">Hungary</option>
                                <option value="IS">Iceland</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="IR">Iran, Islamic Republic Of</option>
                                <option value="IQ">Iraq</option>
                                <option value="IE">Ireland</option>
                                <option value="IM">Isle Of Man</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italy</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JE">Jersey</option>
                                <option value="JO">Jordan</option>
                                <option value="KZ">Kazakhstan</option>
                                <option value="KE">Kenya</option>
                                <option value="KI">Kiribati</option>
                                <option value="KR">Korea</option>
                                <option value="XK">Kosovo</option>
                                <option value="KW">Kuwait</option>
                                <option value="KG">Kyrgyzstan</option>
                                <option value="LA">Lao People's Democratic Republic</option>
                                <option value="LV">Latvia</option>
                                <option value="LB">Lebanon</option>
                                <option value="LS">Lesotho</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libyan Arab Jamahiriya</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Lithuania</option>
                                <option value="LU">Luxembourg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia</option>
                                <option value="MG">Madagascar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldives</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MQ">Martinique</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Micronesia, Federated States Of</option>
                                <option value="MD">Moldova</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="ME">Montenegro</option>
                                <option value="MS">Montserrat</option>
                                <option value="MA">Morocco</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NL">Netherlands</option>
                                <option value="AN">Netherlands Antilles</option>
                                <option value="NC">New Caledonia</option>
                                <option value="NZ">New Zealand</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NF">Norfolk Island</option>
                                <option value="MP">Northern Mariana Islands</option>
                                <option value="NO">Norway</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PS">Palestine, State of</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PH">Philippines</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PL">Poland</option>
                                <option value="PT">Portugal</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="QA">Qatar</option>
                                <option value="RE">Reunion</option>
                                <option value="RO">Romania</option>
                                <option value="RU">Russian Federation</option>
                                <option value="RW">Rwanda</option>
                                <option value="BL">Saint Barthelemy</option>
                                <option value="SH">Saint Helena</option>
                                <option value="KN">Saint Kitts And Nevis</option>
                                <option value="LC">Saint Lucia</option>
                                <option value="MF">Saint Martin</option>
                                <option value="PM">Saint Pierre And Miquelon</option>
                                <option value="VC">Saint Vincent And Grenadines</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome And Principe</option>
                                <option value="SA">Saudi Arabia</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="ZA">South Africa</option>
                                <option value="GS">South Georgia And Sandwich Isl.</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="SD">Sudan</option>
                                <option value="SS">South Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard And Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="SE">Sweden</option>
                                <option value="CH">Switzerland</option>
                                <option value="SY">Syrian Arab Republic</option>
                                <option value="TW">Taiwan</option>
                                <option value="TJ">Tajikistan</option>
                                <option value="TZ">Tanzania</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad And Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TC">Turks And Caicos Islands</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UG">Uganda</option>
                                <option value="UA">Ukraine</option>
                                <option value="AE">United Arab Emirates</option>
                                <option value="GB">United Kingdom</option>
                                <option value="US" selected="selected">United States</option>
                                <option value="UM">United States Outlying Islands</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Viet Nam</option>
                                <option value="VG">Virgin Islands, British</option>
                                <option value="VI">Virgin Islands, U.S.</option>
                                <option value="WF">Wallis And Futuna</option>
                                <option value="EH">Western Sahara</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option>
                            </select>
                        </div>


                        <div><label className={"required"}>{t('form.city')}<span/></label><input id={"city"}
                                                                                                 value={values?.city}
                                                                                                 name={"city"}
                                                                                                 onChange={handleChange}
                                                                                                 required={true}/></div>
                        <div><label>{t('form.state')}<span/></label><input id={"state"}
                                                                           value={values?.state}
                                                                           onChange={handleChange}/>
                        </div>
                        <div><label className={"required"}>{t('form.zip_code')}<span/></label><input id={"zip_code"}
                                                                                                     value={values?.zip_code}
                                                                                                     name={"zip"}
                                                                                                     onChange={handleChange}
                                                                                                     required={true}
                                                                                                     type="number"/>
                        </div>
                    </div>
                    <label className={"required"}>{t('form.address')}<span/></label><input id={"address1"}
                                                                                           value={values?.address1}
                                                                                           name={"address"}
                                                                                           required={true}
                                                                                           onChange={handleChange}/>
                    <div className={"button-row"}/>
                    <GoogleReCaptcha
                        onVerify={verifyRecaptchaCallback}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                    <button type={"submit"}>{t('form.send')}</button>
                </form>
            <LazyLoadImage  src={men}/>
        </div>
    </GoogleReCaptchaProvider>
}
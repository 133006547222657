import visio from "./assets/visio.png?as=webp"
import woman from "./assets/woman.png?as=webp"
import interact from "./assets/interact.png?as=webp"
import dashboard from "./assets/dashboard.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
export default function RemoteWork(){
    const {t, i18n} = useTranslation('remote-work');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={visio}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('home.title')} showDots={false}>
            <div>
                <Paragraph title={t('home.home.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('home.home.text')}}/>
                </Paragraph>
                <Paragraph title={t('home.functionalities.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('home.functionalities.text')}}/>
                </Paragraph>
            </div>
        </RegularSection>
        <ThirdSection src={interact} title={t('support.title')}>
            <div>
                <Paragraph title={t('support.continuity.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('support.continuity.text')}}/>
                </Paragraph>
                <Paragraph title={t('support.configure.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('support.configure.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <ThirdSection src={woman} title={t('visio.title')} reversed={true}>
            <div>
                <Paragraph title={t('visio.meetings.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('visio.meetings.text')}}/>
                </Paragraph>
                <Paragraph title={t('visio.share.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('visio.share.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <ThirdSection src={dashboard} title={t('easy.title')}>
            <div>
                <Paragraph title={t('easy.live.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('easy.live.text')}}/>
                </Paragraph>
                <Paragraph title={t('easy.reports.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('easy.reports.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
    </>)
}
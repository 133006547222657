import recordings from "./assets/recordings.png?as=webp"
import woman from "./assets/woman.png?as=webp"
import one from "./assets/01.png?as=webp"
import two from "./assets/02.png?as=webp"
import three from "./assets/03.png?as=webp"
import four from "./assets/04.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import SixPack from "../../../UI/Sections/SixPack/SixPack";
export default function Voicemail(){
    const {t, i18n} = useTranslation('voicemail');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={recordings}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('explanations.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('explanations.text')}}/>
        </RegularSection>
        <ThirdSection src={woman} title={t('advantages.title')}>
            <div>
                <Paragraph title={t('advantages.call.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.call.text')}}/>
                </Paragraph>
                <Paragraph title={t('advantages.campaign.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('advantages.campaign.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <SixPack packs={[
            {src:one,title:t('list.easy.title'), text:t('list.easy.text')},
            {src:two,title:t('list.record.title'), text:t('list.record.text')},
            {src:three,title:t('list.start.title'), text:t('list.start.text')},
            {src:four,title:t('list.everytime.title'), text:t('list.everytime.text')},
        ]}/>
    </>)
}
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "./Header/Header";
import React, {useEffect, useState, Suspense, lazy, useCallback} from "react";
import Footer from "./Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "./Pages/Pages.scss"
import UnlimitedCalls from "./Pages/UnlimitedCalls/index";
import VirtualNumbers from "./Pages/VirtualNumbers/index";
import CallTransfer from "./Pages/CallTransfer/index";
import SVI from "./Pages/SVI/index";
import Voip from "./Pages/Voip/index";
import C2c from "./Pages/C2c/index";
import Sms from "./Pages/Sms/index";
import CallTags from "./Pages/CallTags/index";
import VideoConferencing from "./Pages/VideoConferencing/index";
import Voicemail from "./Pages/Voicemail/index";
import CallCenter from "./Pages/CallCenter/index";
import ContactCenter from "./Pages/ContactCenter/index";
import IVR from "./Pages/IVR";
import Webphone from "./Pages/Webphone";
import RemoteWork from "./Pages/RemoteWork";
import Teamwork from "./Pages/Teamwork";
import Contact from "./Pages/Contact";
import Requests from "./Pages/Requests";
import Services from "./Pages/Services";
import {getAuthClient} from "../utils/auth";
import store from "../store";
import {appActions} from "../store/app-data-slice";
import Spinner from "../UI/Spinner";
import ReactGA from "react-ga4";
import {capitalize} from "@mui/material";
import NotFound from "./Pages/NotFound";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import Solutions from "./Pages/Solutions";
const Home = lazy(() => import('./Pages/Home/Home'));
const IpPhone = lazy(() => import('./Pages/IpPhone/index'));
const PredictiveDialer = lazy(() => import('./Pages/PredictiveDialer/index'));
const RobotDialer = lazy(() => import('./Pages/RobotDialer/index'));
const AW_TRACKING_ID = process.env.REACT_APP_AW_ID;
const GA_TRACKING_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize([{trackingId: AW_TRACKING_ID}, {trackingId:GA_TRACKING_ID}]);

export default function Mainboard({lang=false, notFound=false}) {
    const {t, i18n} = useTranslation('header');
    const [isLoading, setLoading] = useState(true);
    const [geoInfo, setGeoInfo] = useState({});
    const servicesSub={
        "ip-phone": {title:t('header:services.ip-phone'), component:<IpPhone/>},
        "predictive-dialer": {title:t('header:services.predictive-dialer'), component:<PredictiveDialer/>},
        "robot-dialer": {title:t('header:services.robot-dialer'), component:<RobotDialer/>},
        "unlimited-calls": {title:t('header:services.unlimited-calls'), component:<UnlimitedCalls/>},
        "virtual-numbers": {title:t('header:services.virtual-numbers'), component:<VirtualNumbers/>},
        "call-transfer": {title:t('header:services.call-transfer'), component:<CallTransfer/>},
        "svi": {title:t('header:services.svi'), component:<SVI/>},
        "voip": {title:t('header:services.voip'), component:<Voip/>},
        "c2c": {title:t('header:services.c2c'), component:<C2c/>},
        "video-conferencing": {title:t('header:services.video-conferencing'), component:<VideoConferencing/>},
        "voicemail": {title:t('header:services.voicemail'), component:<Voicemail/>},
        "sms": {title:t('header:services.sms'), component:<Sms/>},
        "call-tags": {title:t('header:services.call-tags'), component:<CallTags/>},
    }
    const solutionsSub={
        "call-center-interface": {title:t('solutions.call-center-interface'), component:<CallCenter/>},
        "contact-center": {title:t('solutions.contact-center'), component:<ContactCenter/>},
        "ivr": {title:t('solutions.ivr'), component:<IVR/>},
        "webphone": {title:t('solutions.webphone'), component:<Webphone/>},
        "remote-work": {title:t('solutions.remote-work'), component:<RemoteWork/>},
        "teamwork": {title:t('solutions.teamwork'), component:<Teamwork/>},
    }
    const countryTolang = {
       "IL":"heb",
       "FR":"fr"
    }
    const routes = {
        "": {title:t('home'), component:<Home/>},
        "services": {title:t('services.title'), component: <Services routes={servicesSub} title={t('services.title')}/>,
            subRoutes: servicesSub},
        "pricing": {title:t('pricing')},
        "why_us": {title:t('header:why-cool-telecom')},
        "solutions": {title:t('solutions.title'), component: <Solutions routes={solutionsSub} title={t('solutions.title')}/>,
            subRoutes: solutionsSub},
        "contact_us": {title:t('header:contact-us'),component:<Contact/>}
    }
    const selectedRoute = useSelector(state => state.app.route);
    const selectedSub = useSelector(state => state.app.subRoute);
    const mobileMenuState = useSelector(state => state.app.isMobileMenuOpen);
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = getAuthClient();

    const parseRoute = (route) =>{
        const regex = /\/(.*?)\//g;
        const matches = route.match(regex);

        const outputArray = (matches??[]).map(match => match.substring(1, match.length - 1));
        return outputArray.join(", ");
    }

    useEffect(() => {
        setLoading(true)
        auth.getGeoInfo().then(res => {
            dispatch(appActions.updateLocation(res))
            let parsed = location.pathname.length > 3? parseRoute(location.pathname).toUpperCase():lang?lang.toUpperCase():"None"
            console.log('parsed', parsed);
            let ipLang = countryTolang[res.country_code] ?? "fr"
            if(parsed === "None"){
            }
            else if(i18n.language !== parsed.toLowerCase()){
            }
            setLoading(false)
            setGeoInfo(res)
        }).catch(err => {
            setLoading(false)
        })
    },[])

    useEffect(()=>{
        if(Object.values(geoInfo).length > 0){
            //setLoading(false)
        }
    },[geoInfo])

    useEffect(()=>{
        if(lang){
            let parsed = location.pathname.length > 3? parseRoute(location.pathname).toUpperCase():lang?lang.toUpperCase():"None"
            if(parsed === "None"){
                i18n.changeLanguage("fr");
                dispatch(appActions.updateLanguage("fr"))
            }
            else {
                i18n.changeLanguage(parsed.toLowerCase());
                dispatch(appActions.updateLanguage(parsed.toLowerCase()))
            }
        }
        else{
            i18n.changeLanguage("fr");
            dispatch(appActions.updateLanguage("fr"))
        }
    },[lang])

    useEffect(()=>{
        //console.log(location.pathname.slice(3,))
        let currPath = `/${i18n.language}/${selectedRoute}/${selectedSub}`
        //console.log(currPath.slice(3,))
        if(lang){
            if (location.pathname.slice(3,) === currPath.slice(3,)
                || location.pathname.slice(3,) === currPath.slice(3, -1)
                || location.pathname.slice(3,) === currPath.slice(4)
                || location.pathname.slice(4,) === currPath.slice(3)
                || (currPath.slice(3,-1) === "/home" && location.pathname ==="/")
            ) {
                //navigate(`/${lang?lang:i18n.language}${(selectedRoute??"").length>0? `/${selectedRoute}`:""}${(selectedSub??"").length>0? `/${selectedSub}`:""}`)
                //i18n.changeLanguage(lang);
            }
        }
        else if(i18n.language !== "def"){
            setLoading(false)
        }
    },[i18n.language])

    const checkUrlExistence = (url, routes) => {
        const urlParts = url.split('/');
        const mainRoute = urlParts[0];

        if (!routes[mainRoute]) {
            return false;
        }

        if (urlParts.length > 1) {
            const subRoute = urlParts[1];
           return routes[mainRoute].subRoutes && routes[mainRoute].subRoutes[subRoute]? {route:mainRoute, subRoute}:subRoute.length > 1? {route: mainRoute, subRoute: "not-found"}:{route: mainRoute}
        }

        return {route:mainRoute};
    };

    useEffect(()=>{
        let languages = i18n.services.languageUtils.options.whitelist;
        let splitUrl = location.pathname.split("/")[1]??""
        let pathLang = languages.filter(lang => splitUrl.includes(lang))[0]??false
        let currRoute = location.pathname.split(`/${pathLang}/`)[1]??""
        if(location.pathname === "/" || currRoute === ""){
            if(currRoute === "" && pathLang){
                i18n.changeLanguage(pathLang);
                dispatch(appActions.updateLanguage(pathLang))
            }
            dispatch(appActions.updateRoute("home"))
            dispatch(appActions.updateSubRoute(""))
        }
        else{
            let checkRoute = checkUrlExistence(currRoute, routes)
            if(checkRoute && checkRoute !== undefined){
                dispatch(appActions.updateRoute(checkRoute?.route))
                dispatch(appActions.updateSubRoute(checkRoute?.subRoute??""))
            }
        }
        if(lang.length > 0 && routes[selectedRoute] !== undefined){
            let currRoute = routes[selectedRoute]
            let title = "subRoutes" in currRoute? capitalize((selectedSub??"").replace("_"," ").replace("-"," ")) : capitalize(selectedRoute.replace("_"," ").replace("-"," "))
            ReactGA.send({ hitType: "pageview", page_location: `https://www.cool-telecom.com/${selectedRoute}/${selectedSub}`, language:`${lang}`, page_title:title});
        }
    },[location,i18n.language])

    const [windowWidth, setWindowSize] = useState(window.innerWidth);

    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    const handleScroll = (e) =>{
        var chat_bouton = document.getElementById('woot-widget-bubble-icon');
        if (e && chat_bouton && windowWidth < 1400) {
            if(window.$chatwoot.isOpen){
                window.$chatwoot.toggleBubbleVisibility("hide"); 
              }
        }
        else if(chat_bouton){
            window.$chatwoot.toggleBubbleVisibility("show"); 
        }
        const driftElement = document.getElementById("drift-frame-controller")??false;
        if (e && driftElement && windowWidth < 1400) {
            driftElement.classList.add("hidden");
        }
        else if(driftElement){
            driftElement.classList.remove("hidden");
        }
    }

    useBottomScrollListener(()=>handleScroll(true));

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleScroll(false));

        return () => { // return a cleanup function to unregister our function since it will run multiple times
            window.removeEventListener("scroll", (e) => handleScroll(false));
        };
    }, []);

    return (
        <Suspense fallback={<Spinner/>}>
        {isLoading?<Spinner/>:<>
        <Header routes={routes} geoInfo={geoInfo}/>
        <div id="mainboard" className={`base ${Object.values(geoInfo).length > 0 && "loaded "}${(selectedSub !== "" && selectedRoute !=="requests"?selectedSub:((location.pathname.split(`/${i18n.language}/`)[1])??"home").replace("/"," "))}`.trim()} style={!mobileMenuState?{overflow:"hidden"}:{}}>
        <Routes>
            {!notFound && Object.keys(routes).map(route => {
                let el = routes[route].component
                if("subRoutes" in routes[route]){
                    let subs = routes[route]?.subRoutes
                    let firstSubRoute = subs[Object.keys(subs)[0]]
                    return <Route exact path={`/${route}`} key={`/${route}`} >
                        <Route index element={routes[route]?.component}  key={`/${route}`}/>
                    {Object.keys(subs).map(sub_route => {
                        let sub_comp = subs[sub_route].component ?? <div style={{
                            height: "100%",
                            background: "var(--gray-cultured)",
                        }}/>
                        return <Route exact path={`${sub_route}`} key={`${sub_route}`}  element={sub_comp}/>
                    })}
                        <Route exact path="*" key={`*`}  element={<NotFound subRoute={true} route={route}/>} />
                    </Route>
                }
                else{
                    return <Route path={`/${route}`} key={`/${route}`} element={el??<div style={{
                        height: "100%",
                        background: "var(--gray-cultured)",
                    }}/>}/>
                }
            })}
            <Route path={"/requests/:type"} key={"/requests/:type"} element={<Requests/>}/>}
            <Route exact path="*"  key={`*`}  element={<NotFound/>} />
        </Routes>
        </div>
        <Footer/></>}
    </Suspense>)
}
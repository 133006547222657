import React, {useEffect, useState} from "react";
import UnlimitedCalls from "../components/Pages/UnlimitedCalls";
import VirtualNumbers from "../components/Pages/VirtualNumbers";
import CallTransfer from "../components/Pages/CallTransfer";
import SVI from "../components/Pages/SVI";
import Voip from "../components/Pages/Voip";
import C2c from "../components/Pages/C2c";
import VideoConferencing from "../components/Pages/VideoConferencing";
import Voicemail from "../components/Pages/Voicemail";
import Sms from "../components/Pages/Sms";
import CallTags from "../components/Pages/CallTags";
import CallCenter from "../components/Pages/CallCenter";
import ContactCenter from "../components/Pages/ContactCenter";
import IVR from "../components/Pages/IVR";
import Webphone from "../components/Pages/Webphone";
import RemoteWork from "../components/Pages/RemoteWork";
import Teamwork from "../components/Pages/Teamwork";
import PredictiveDialer from "../components/Pages/PredictiveDialer";
import IpPhone from "../components/Pages/IpPhone";
import RobotDialer from "../components/Pages/RobotDialer";

export function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            entry.isIntersecting && setIntersecting(entry.isIntersecting)
        );

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}
export function servicesSub(t){ return {
    "ip-phone": {title:t('header:services.ip-phone'), component:<IpPhone/>},
    "predictive-dialer": {title:t('header:services.predictive-dialer'), component:<PredictiveDialer/>},
    "robot-dialer": {title:t('header:services.robot-dialer'), component:<RobotDialer/>},
    "unlimited-calls": {title:t('header:services.unlimited-calls'), component:<UnlimitedCalls/>},
    "virtual-numbers": {title:t('header:services.virtual-numbers'), component:<VirtualNumbers/>},
    "call-transfer": {title:t('header:services.call-transfer'), component:<CallTransfer/>},
    "svi": {title:t('header:services.svi'), component:<SVI/>},
    "voip": {title:t('header:services.voip'), component:<Voip/>},
    "c2c": {title:t('header:services.c2c'), component:<C2c/>},
    "video-conferencing": {title:t('header:services.video-conferencing'), component:<VideoConferencing/>},
    "voicemail": {title:t('header:services.voicemail'), component:<Voicemail/>},
    "sms": {title:t('header:services.sms'), component:<Sms/>},
    "call-tags": {title:t('header:services.call-tags'), component:<CallTags/>},
}}
export function solutionsSub(t){ return {
    "call-center-interface": {title:t('header:solutions.call-center-interface'), component:<CallCenter/>},
    "contact-center": {title:t('header:solutions.contact-center'), component:<ContactCenter/>},
    "ivr": {title:t('header:solutions.ivr'), component:<IVR/>},
    "webphone": {title:t('header:solutions.webphone'), component:<Webphone/>},
    "remote-work": {title:t('header:solutions.remote-work'), component:<RemoteWork/>},
    "teamwork": {title:t('header:solutions.teamwork'), component:<Teamwork/>},
}}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

import React from "react";
import "../Services/Services.scss";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../../../UI/Breadcrumbs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Helmet} from "react-helmet-async";

export default function Solutions({routes, title}){
    const {t, i18n} = useTranslation('common');
    return <>
        <Helmet>
            <title>{`${title} - Cool Telecom`}</title>
        </Helmet>
        <h1>{title}</h1>
        <Breadcrumbs/>
        <div className={"tabs"}>
        {Object.keys(routes).map((key, index) =>{
            let route = routes[key]
            let icon = "";
            try{
                icon = require(`./assets/new/${key}.png?as=webp`);
            }
            catch (e) {
                console.log(e)
            }
            return <a href={`/${i18n.language}/solutions/${key}`} key={`/solutions/${key}`} style={{
                background:index%2 === 0?"var(--blue-sea)":"var(--green-crayola)"
            }}>
                <LazyLoadImage  src={icon??""}/>
                <span>{route?.title??"Route"}</span>
            </a>
        })}
    </div></>
}
import {combineReducers, configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import appDataSlice from "./app-data-slice";


const combinedReducer = combineReducers({
    app: appDataSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        sessionStorage.clear();
        state = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})
export default store;
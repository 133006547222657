import robot from "./assets/robot.png?as=webp"
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
export default function RobotDialer(){
    const {t, i18n} = useTranslation('robot-dialer');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={robot} showButton={false}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('automate.title')} showDots={false} linePos={"under"}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('automate.text')}}/>
        </RegularSection>
    </>)
}
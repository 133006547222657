import world from "./assets/world.png?as=webp"
import connect from "./assets/connect.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import Countries from "../../../UI/Sections/Countries/Countries";
export default function UnlimitedCalls(){
    const {t, i18n} = useTranslation('unlimited-calls');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={world}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('international.title')} showDots={false} linePos={"under"}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('international.text')}}/>
        </RegularSection>
        <ThirdSection src={connect}>
            <div>
                <Paragraph title={t('presence.international.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('presence.international.text')}}/>
                </Paragraph>
                <Paragraph title={t('presence.quality.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('presence.quality.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <RegularSection title={t('destination.title')} linePos={"under"} background={"var(--blue-azure)"}>
            <Countries/>
        </RegularSection>

    </>)
}
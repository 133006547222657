import React, {Component} from 'react';
//import webpLoad from "./Spinner/assets/loadingAnim.webp";
//import gifLoad from "./Spinner/assets/loadingAnim.gif";
//import {useWebPSupportCheck} from "react-use-webp-support-check";

const Spinner =({children, ...props}) => {
    return (
        <div style={{ margin: 'auto', width: '50%', height: "100vh"}}>
            <div style={{ backgroundImage: `url(${require("./Spinner/assets/loadingAnim.webp")})`, backgroundRepeat: 'no-repeat', backgroundSize:'contain', backgroundPosition:'center center', height: '80vh'}}>
                {children}
            </div>
        </div>
    );
}

export default Spinner;
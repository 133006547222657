import visio from "./assets/visio.png?as=webp"
import collab from "./assets/collab.png?as=webp"
import interact from "./assets/interact.png?as=webp"
import dashboard from "./assets/dashboard.png?as=webp"
import ThirdSection from "../../../UI/Sections/ThirdSection/ThirdSection";
import RegularSection from "../../../UI/Sections/RegularSection/RegularSection";
import TitleHeader from "../../../UI/Sections/TitleHeader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./style.css";
import Paragraph from "../../../UI/Paragraph/Paragraph";
import SixPack from "../../../UI/Sections/SixPack/SixPack";
import share from "./assets/share.png?as=webp";
import voicemail from "./assets/welcome.png?as=webp";
import screen from "./assets/screen.png?as=webp";
import conference from "./assets/conference.png?as=webp";
import sms from "./assets/sms.png?as=webp";
export default function Teamwork(){
    const {t, i18n} = useTranslation('teamwork');
    const isMobile = useSelector(state => state.app.isMobile);
    return(<>
        <TitleHeader title={t('head.title')} text={t('head.text')} img={collab}>
            {!isMobile ? <div style={{
                "display": "flex",
                "alignItems": "flex-start",
                "padding": "1% 10% 1% 0",
                background: "var(--gray-cultured)",
                position: "sticky",
                direction: "initial"
            }}>
            </div>:<></>}
        </TitleHeader>
        <RegularSection title={t('exchange.title')} showDots={false}>
            <span className={'small-text'} dangerouslySetInnerHTML={{__html: t('exchange.text')}}/>
        </RegularSection>
        <ThirdSection src={interact}>
            <div>
                <Paragraph title={t('easy.organized.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('easy.organized.text')}}/>
                </Paragraph>
                <Paragraph title={t('easy.visio.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('easy.visio.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <ThirdSection src={dashboard} title={t('collaboration.title')} reversed={true}>
            <div>
                <Paragraph title={t('collaboration.team.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('collaboration.team.text')}}/>
                </Paragraph>
                <Paragraph title={t('collaboration.calls.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('collaboration.calls.text')}}/>
                </Paragraph>
                <Paragraph title={t('collaboration.custom.title')} reduced={true}>
                    <span dangerouslySetInnerHTML={{__html: t('collaboration.custom.text')}}/>
                </Paragraph>
            </div>
        </ThirdSection>
        <SixPack title={t('functionalities.title')}
                 packs={[
                     {src:share,title:t('functionalities.share.title'), text:t('functionalities.share.text')},
                     {src:voicemail,title:t('functionalities.voicemail.title'), text:t('functionalities.voicemail.text')},
                     {src:visio,title:t('functionalities.visio.title'), text:t('functionalities.visio.text')},
                     {src:screen,title:t('functionalities.screen.title'), text:t('functionalities.screen.text')},
                     {src:conference,title:t('functionalities.conference.title'), text:t('functionalities.conference.text')},
                     {src:sms,title:t('functionalities.sms.title'), text:t('functionalities.sms.text')}
                 ]}/>
    </>)
}
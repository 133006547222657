import logo from "./assets/Logo_cool_telecom.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PhoneIcon from '@mui/icons-material/Phone';
import "./Header.scss";
import Navbar from "../../UI/Navbar/Navbar";
import Index from "../../UI/LanguageSelection";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {appActions} from "../../store/app-data-slice";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ChatwootWidget from "../Chatwoot";

export default function Header({routes, geoInfo}){
    const isMobile = useSelector(state => state.app.isMobile);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [init,setInit] = useState(false);
    const {t, i18n} = useTranslation('header');
    const selectedRoute = useSelector(state => state.app.route);
    const selectedSub = useSelector(state => state.app.subRoute);
    const userLocation = geoInfo
    const phones = {
        "+44":"(+44)2035190975",
        "+33":"(+33)159180001",
        "+32":"(+32)28992884",
        "+972":"(+972)779909233"
    }
    const navigate = useNavigate();
    const [windowWidth, setWindowSize] = useState(window.innerWidth);

    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    useEffect(()=>{
        dispatch(appActions.updateMobileMenuState(!collapsed))
    },[collapsed])
    useEffect(()=>{
        if(collapsed){
            setCollapsed(false)
        }
    },[i18n.language,selectedSub, selectedRoute])
    useEffect(()=>{
        if(!init && collapsed){
            setInit(true)
        }
        try{
            const driftElement = document.getElementById("drift-frame-controller");
            if (driftElement && (isMobile || windowWidth < 1400)) {
                if (collapsed) {
                    driftElement.classList.add("hidden");
                } else {
                    driftElement.classList.remove("hidden");
                }
            }
        }
        catch (e) {
            console.log(e)
        }
    },[collapsed])

    useEffect(() => {
        var chat_bouton = document.getElementById('woot-widget-bubble-icon');
        if (chat_bouton && (isMobile || windowWidth < 1400)) {
            if (collapsed) {
                chat_bouton.addEventListener('click', function(event) {
                    if(window.$chatwoot.isOpen){
                      window.$chatwoot.toggleBubbleVisibility("hide"); 
                    }
                });
            } window.addEventListener("chatwoot:on-message", function (e) {
                window.$chatwoot.toggleBubbleVisibility("show"); 
        });
    }
      },[collapsed]);
    return <div className={"header" + (!collapsed?` collapsed${init?" init":""}`:`${init?" init":""}`)}>
        {!isMobile && windowWidth > 1400?<>
        <LazyLoadImage  className={"logo"} style={{maxHeight: "70px"}} src={logo} onClick={()=>{
            navigate(`/${i18n.language}`)
        }}/>
        <Navbar titles={routes} setCollapsed={setCollapsed}/>
        <span className="text-link" style={{display:"flex", columnGap: "10px",
            direction: "initial"}}><PhoneIcon style={{
            maxHeight: "15px",
            border: "2px solid black",
            borderRadius: "50px",
            padding: "5px 1px",
            fill: "black"
        }}/> <a href={"tel:"+(phones[userLocation?.country_calling_code]??"(+44)2035190975")}>{phones[userLocation?.country_calling_code]??"(+44)2035190975"}</a>
                  <div className="langSelecWrapper">
                    <Index showText={true}/>
                  </div></span>
        <button className="round-light" onClick={()=>{
            navigate(`/${i18n.language}/requests/quotation`)
            dispatch(appActions.updateRoute("requests"))
            dispatch(appActions.updateSubRoute(""))
        }}>
            {t('quote-request')}
        </button></>:
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "-webkit-fill-available",
                justifyContent: "space-between",
                padding: "2% 5%",
                direction: "ltr"
            }}>
                <LazyLoadImage  className={"logo"} style={{maxHeight: "70px"}} src={logo}/>
                <button className={`menu-button ${collapsed?"opened":""}`.trim()} onClick={()=>{setCollapsed(!collapsed)}}
                        aria-label="Main Menu">
                    <svg className={collapsed?"opened":""} width="100" height="100" viewBox="0 0 100 100">
                        <path className="line line1"
                              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
                        <path className="line line2" d="M 20,50 H 80"/>
                        <path className="line line3"
                              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
                    </svg>
                </button>
            </div>
            <Navbar titles={routes}/>
        </>
        }
    </div>
}